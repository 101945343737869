import { render, staticRenderFns } from "./detail1-seo.vue?vue&type=template&id=a809d286&scoped=true&"
import script from "./detail1-seo.vue?vue&type=script&lang=ts&"
export * from "./detail1-seo.vue?vue&type=script&lang=ts&"
import style0 from "./detail1-seo.vue?vue&type=style&index=0&lang=less&"
import style1 from "./detail1-seo.vue?vue&type=style&index=1&id=a809d286&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a809d286",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SectionTitle: require('/root/workspace/com.jufair.vue_H3C2/components/SectionTitle/index.vue').default})
